import { Component, OnInit } from "@angular/core";
import { AngularFirestore, DocumentChangeAction } from "@angular/fire/firestore";
import * as timeago from 'timeago.js';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: "my-app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
  trigger('items', [
    transition(':enter', [
      style({ maxHeight: 0, padding: '0', opacity: 0 }),
      animate('0.4s ease-in-out', style({ padding: '7px 10px', maxHeight: '50px' })),
      animate('0.7s ease-in-out', style({ opacity: 1 }))
    ])
  ])
]
})
export class AppComponent implements OnInit {
  items: any = [];
  constructor(private db: AngularFirestore) {}

  async ngOnInit() {
    try {
      this.items = this.db
        .collection("news", ref =>
          ref
            .where("is_active", "==", true)
            .orderBy("created_at", "desc")
            .limit(20)
        )
        .snapshotChanges()
        .subscribe((res: DocumentChangeAction<any>[]) => {
          const newItems = [];
          for (const result of res) {
            const data = result.payload.doc.data();
            newItems.push({
              id: result.payload.doc.id,
              ...data
            });
          }

          this.items = newItems;
          console.log(this.items);

          // setTimeout(() => this.add(), 5500);
      });
    } catch (e) {
      console.log(e);
    }

    setInterval(async () => {
      try {
        await fetch('https://mm.stokery.com');
      } catch (e) {
        console.log(e);
      }
    }, 60000);

    console.log(timeago.format(new Date()));
  }

  trackByFn(index, item) {
    return item.id || index;
  }

  alaWenEntak (when) {
    return timeago.format(new Date(when));
  }

  name = "Angular";
}
