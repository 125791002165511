export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBPbRuHRc2cnPKmyftq2a_x1hQ04dj35tA",
    authDomain: "avators1.firebaseapp.com",
    databaseURL: "https://avators1.firebaseio.com",
    projectId: "avators1",
    storageBucket: "avators1.appspot.com",
    messagingSenderId: "979694609222",
    appId: "1:979694609222:web:c8263e1690cfeaa5fb7b47"
  }
};